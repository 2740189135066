<template>
  <Layout>
    <div class="upi-collect">
      <div class="header">
        <h1>₹ {{ amount }}</h1>
      </div>
      <div class="section">
        <h3>Complete your payment</h3>
        <h4 class="upi-id">Entered UPI ID: {{ upiId }}</h4>
        <van-steps direction="vertical" :active="-1">
          <van-step style="padding-bottom: 50px;">
            <h4>Go to the UPI APP</h4>
          </van-step>
          <van-step>
            <h4>
              Check pending requests and approve payment by entering UPI PIN
            </h4>
          </van-step>
        </van-steps>
        <div class="count-down">
          <p class="title">PAGE EXPIRES IN</p>
          <van-count-down
              :time="myData.time"
              format="mm : ss "
              @finish="countdownFinish"
          />
          <p class="text">MIN : SEC</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <button v-if="showBtn" @click="checkStatus">PAY COMPLETED</button>
    </div>
  </Layout>
</template>
<script setup>
import Layout from "@/layout/index.vue";
import {useRoute, useRouter} from "vue-router";
import {onBeforeUnmount, onMounted, onUnmounted, reactive, ref} from "vue";
import {getPaymentResult} from "@/api/payment";
import {Dialog, Toast} from "vant";

let route = useRoute();
let router = useRouter();

let myData = reactive({
  firstLoad: true,
  time: 0,
  showBtn: false,
});

// 交易数据
let originalOrderId = route.query.originalOrderId;
let amount = route.query.amount;
let upiId = route.query.upiId;
let sessionId = sessionStorage.getItem("sessionId");

onMounted(async () => {
  //防止页面后退  使用在vue时 挂载到mounted中
  if (window.history && window.history.pushState) {
    history.pushState(null, null, document.URL); //这里有没有都无所谓，最好是有以防万一
    window.addEventListener("popstate", goBack, false); // 回退时执行goback方法
  }
  let data = {
    originalOrderId: originalOrderId,
  };
  await getPaymentResult(data, sessionId)
      .then((response) => {
        let total = 300000;
        let time =
            new Date(response.data.currentTime).getTime() -
            new Date(response.data.createTime).getTime();
        myData.time = total - time;
        myData.firstLoad = false;
      })
      .catch(function (error) {
      });
  if (
      originalOrderId === "undefined" ||
      originalOrderId == null ||
      originalOrderId === ""
  ) {
    await router.push({
      path: "/paymentResult",
      query: {
        result: "FAILURE",
        type: "UPI Collect",
        amount: amount,
      },
    });
  } else {
    // 轮询结果
    await upiResult();
  }
});
onUnmounted(() => {
  window.removeEventListener("popstate", goBack, false);
});

let countdownFinish = () => {
  if (!myData.firstLoad) {
    myData.showBtn = true;
  }
};

let goBack = () => {
  Dialog.confirm({
    title: "Tips",
    message: "Are you sure you want to leave the page?",
    confirmButtonText: "Sure",
    cancelButtonText: "Cancel",
  }).then(() => {
    // 跳转到空白页
    window.location.href = sessionStorage.getItem('redirectLink');
    window.close();
  });
};

let retryCount = ref(0);
let getResult;
//UPI支付结果
let upiResult = async () => {
  let data = {
    originalOrderId: originalOrderId,
  };
  getPaymentResult(data, sessionId)
      .then((response) => {
        if (
            response.data &&
            (response.data.status === "SUCCESS" ||
                response.data.status === "FAILURE")
        ) {
          //已支付或支付失败
          clearTimeout(getResult);
          router.push({
            path: "/paymentResult",
            query: {
              result: response.data.status,
              type: "UPI Collect",
              amount: amount,
              paymentId: response.data.paymentId,
              upiRefId: response.data.bankOrderId,
            },
          });
        } else {
          //未支付重试
          retryCount.value++;
          if (retryCount.value > 100) {
            clearTimeout(getResult);
          } else {
            if (getResult == null) {
              getResult = setInterval(() => {
                upiResult();
              }, 3000);
            }
          }
        }
      })
      .catch(function (error) {
      });
};

onBeforeUnmount(() => {
  clearTimeout(getResult);
});

//手动检查是否成功
const checkStatus = () => {
  let getPaymentResultData = {
    originalOrderId: originalOrderId,
  };
  getPaymentResult(getPaymentResultData, sessionId)
      .then((response) => {
        if (
            response.data &&
            (response.data.status === "SUCCESS" ||
                response.data.status === "FAILURE")
        ) {
          router.push({
            path: "/paymentResult",
            query: {
              result: response.data.status,
              type: "UPI Collect",
              amount: amount,
              paymentId: response.data.paymentId,
              upiRefId: response.data.bankOrderId,
            },
          });
        } else {
          Toast("PENDING");
        }
      })
      .catch(function (error) {
      });
};

</script>
<style scoped lang="scss" type="text/scss">
.van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0 !important;
}

.bottom {
  width: 92%;
  position: absolute;
  bottom: 5%;
  color: #999999;
  font-size: 1.2rem;
  height: 50px;
  line-height: 50px;

  button {
    float: right;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #ff9933;
    border-radius: 48px;
  }
}

.upi-collect {
  width: 100%;
  height: 100%;

  .header {
    text-align: center;
    overflow: hidden;

    h1 {
      margin: 0 0 6%;
      color: #333333;
    }

    p {
      color: #666666;
      margin: 2% 0 4%;
    }
  }

  .section {
    color: #333333;

    .vpa-input {
      font-size: 1.2rem;
      margin: 16px 0;
      border: 1px solid #eee;
      border-radius: 4px;
    }

    .error-tips {
      color: rgb(203, 7, 7);
    }

    .count-down {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      font-size: 1.1rem;

      .title {
        font-size: 1.2rem;
        color: #444;
        margin-bottom: 20px;
      }

      .text {
        margin-top: 5px;
      }
    }
  }

  .button {
    float: right;
    width: 24%;
    height: 30px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #ff9933;
    border-radius: 15px;
    opacity: 1;
  }
}

.upi-id {
  font-size: 1.05rem;
  color: #666666;
  margin-top: 0.3rem;
}
</style>
